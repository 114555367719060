import { useEffect, useState } from "react"
import { Box, CircularProgress, Grid } from "@mui/material"
import { Form } from "formik"
import _isArray from "lodash/isArray"
import _isFunction from "lodash/isFunction"
import FormFieldset from "./FormFieldset"
import constants from "../entity/constants"

/**
 * Recursively processes an array of content objects to extract asynchronous select data.
 *
 * This function iterates over each content object in the provided array. If a content item
 * represents a field container (as determined by its facet type), it recursively
 * processes its child fields. For content items with a type corresponding to asynchronous select,
 * it accumulates an object containing the content's identifier and a fetcher function.
 * 
 * Note: The logic for processing content of type FACET_TYPE_ARRAY is temporarily omitted.
 *
 * @param {Array} contents an array of content objects. Each object may represent a fieldset, 
 *                         a field group, or an asynchronous select component.
 * @returns {Array<Object>} an array of objects, each having an `id` and a `fetcher` property,
 *                          corresponding to async select components.
 */
const processContentPromises = (contents) => {
    return contents.reduce((acc, content) => {
        if (content.facet === constants.FACET_TYPE_FIELDSET) {
            return acc.concat(processContentPromises(content.options.fields))
        } else if (content.facet === constants.FACET_TYPE_FIELD_GROUP) {
            return acc.concat(processContentPromises(content.options.fields))
        } else if (content.type === constants.FACET_TYPE_ARRAY) {
            //return acc.concat(processContentPromises(content.fields))
        } else if (content.type === constants.ASYNC_SELECT_TYPE) {
            acc.push({
                id: content.id,
                fetcher: content.optionsFetcher,
            })
        }

        return acc
    }, [])
}

/**
 * Recursively updates the options of a specific field within a contents array.
 *
 * @param {Array} contents the array of content objects to update.
 * @param {string} id the id of the field to update.
 * @param {Object} options the new options to set for the specified field.
 * @returns {Array} the updated array of content objects.
 */
const updateContentsFieldOptions = (contents, id, options) => {
    return contents.map((content) => {
        if (content.facet === constants.FACET_TYPE_FIELDSET ||content.facet === constants.FACET_TYPE_FIELD_GROUP) {
            // if the content is a field container, update the fields recursively
            return {
                ...content,
                options: {
                    ...content.options,
                    fields: updateContentsFieldOptions(content.options.fields, id, options),
                },
            }
        } else if (content.type === constants.FACET_TYPE_ARRAY) {
            /*return {
                ...content,
                fields: updateContentsField(content.fields, id, value),
            }*/
        } else if (content.id === id) {
            // if the content is the field we are looking for, update the options
            return { ...content, options: options }
        }

        // if the content is not a field container or the field we are looking for, return it as is
        return content
    })
}


/**
 * Pre-processes the form content by handling asynchronous operations.
 *
 * @param {Array} contents the array of form contents to be processed.
 * @param {Function} onSuccess callback function to be called with the new form content when all fetchers succeed.
 * @param {Function} onError callback function to be called when any fetcher fails.
 */
const preProcessFormContent = (contents, onSuccess, onError) => {
    const promises = processContentPromises(contents)

    if (promises.length === 0) {
        // if there are no promises, we can return the contents as is
        onSuccess(contents)
        return
    }

    const fetchers = promises.map((promise) => promise.fetcher())

    Promise.all(fetchers)
        .then((results) => {
            const newFormContent = results.reduce((acc, result, index) => {
                // find the field in the contents and set the options
                return updateContentsFieldOptions(acc, promises[index].id, result)
            }, contents)

            onSuccess(newFormContent)
        })
        .catch((error) => {
            console.error(error)
            onError(error)
        })
}


/**
 * Processes the form contents based on the provided entity and variant.
 *
 * @param {Object} entity the entity object to be used in the form processing.
 * @param {Array} contents an array of content objects to be processed.
 * @param {string} variant the variant type which affects the layout of the form.
 * @returns {Array|null} teturns an array of processed form elements or null if contents is not an array.
 */
const processForm = (entity, contents, variant) => {
    if (!_isArray(contents)) {
        return null
    }

    return contents
        .filter((content) =>
            _isFunction(content.options.condition)
                ? content.options.condition(entity)
                : true
        )
        .map((content, index) => {
            switch (content.facet) {
                case "fieldset":
                    return (
                        <Grid key={index} item xs={12} md={variant === "default" ? 6 : 12}>
                            <FormFieldset
                                key={index}
                                title={content.options.title}
                                fields={content.options.fields}
                                entity={entity}
                            />
                        </Grid>
                    );
                default:
                    return null
            }
        })
}

const EntityForm = ({ entity, formContent, variant, actionsOnSubmit }) => {
    const [formFields, setFormFields] = useState(null)

    useEffect(() => {
        if (!formFields) {
            preProcessFormContent(
                formContent,
                setFormFields,
                (error) => {
                    console.error("Error processing form content", error)
                }
            )
        }
    }, [formContent, formFields])

    variant = variant || "default"

    if (!formFields) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress size={20} />
            </Box>
        )
    }

    return (        
        <Form>
            <Grid container spacing={2}
                sx={{
                    '& .MuiFormControl-root': { width: { xs: '100%' } }

                }}
            >
                {processForm(entity, formFields, variant)}
            </Grid>
        </Form>
    )
}

export default EntityForm