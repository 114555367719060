import ValueSelectorDialog from "../ValueSelectorDialog/ValueSelectorDialog"

const defaultValueFormatter = (value) => ({
    id: value.id,
    label: value.name,
    entity: value,
})

const EntitySelectorAction = ({ table, multiple, valueFormatter, selection, onAction, onClose }) => {

    const handleOnConfirm = (data) => {
        onClose()
        onAction(data)
    }

    return (
        <ValueSelectorDialog
            table={table}
            selection={selection}
            valueFormatter={valueFormatter || defaultValueFormatter}
            multipleSelection={multiple || false}
            onConfirm={handleOnConfirm}
            onCancel={onClose}
        />
    )
}

export default EntitySelectorAction