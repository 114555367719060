import { createArrayField, createCallbackField, validationTypeMapping, createDataSetFacet, createField, createFacetsContainer, createFacetField, createObjectStatusField, createCommonVisibilityMapping, productContainerStateMapping, createProductStateMapping, createLinkField } from '../../../../components/helpers/fieldHelper'
import { Link } from '@mui/material'
import DisfrutareLink from '../../../../components/Volcano/Disfrutare/DisfrutareLink'
import availableRoutes from '../../../../routes/availableRoutes'
import constants from '../../../../components/entity/constants'
import _isEmpty from "lodash/isEmpty"

export const getHeaderContent = (t) => [
    createDataSetFacet(t("catalog.products.view.facet_information"), [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createField(
            "sku",
            t("catalog.products.fields.sku"),
            constants.STRING_TYPE,
            null,
            (entity) => !_isEmpty(entity.sku)
        ),
        createCallbackField(
            "experience.name",
            t("catalog.products.fields.experience"),
            constants.STRING_TYPE,
            (value, entity) => <DisfrutareLink href={availableRoutes.disfrutare_experience_view.path.replace(":entityId", entity.experience.id)}>{entity.experience.name}</DisfrutareLink>,
            (entity) => !_isEmpty(entity.experience)
        ),
        createCallbackField(
            "validation_type",
            t("catalog.products.fields.validation_type.name"),
            constants.STRING_TYPE,
            (value) => {
                const translatedValue = validationTypeMapping(t)[value];
                return translatedValue ? translatedValue : value;
            }
        ),
        createField(
            "supplier.name",
            t("catalog.products.fields.supplier"),
            constants.STRING_TYPE,
            null,
            (entity) => entity.supplier
        ),
        createCallbackField(
            "remote_product_provider",
            t("catalog.products.fields.remote_product_provider"),
            constants.STRING_TYPE,
            (value) => value ? value.alias : null,
            (entity) => entity.remote_product_provider
        ),
    ]),
    createDataSetFacet(t("catalog.products.view.facet_additional_information"), [
        createCallbackField(
            "languages",
            t("catalog.products.fields.languages"),
            constants.STRING_TYPE,
            (values) => values.join(", ").toUpperCase(),
            (entity) => !_isEmpty(entity.languages)
        ),
        createArrayField(
            createField(
                "tags",
                t("catalog.products.fields.tags"),
                constants.ARRAY_TYPE,
                null,
                (entity) => !_isEmpty(entity.tags)
            ),
            createLinkField(
                createField(
                    "name",
                    null,
                    constants.TEXT_TYPE
                ),
                {
                    route: "tags",
                    field: "id",
                }
            )            
        ),
        createArrayField(
            createField(
                "tags",
                t("catalog.products.fields.tags"),
                constants.ARRAY_TYPE,
                null,
                (entity) => !_isEmpty(entity.tags)
            ),
            "name"            
        ),
    ]),
    createDataSetFacet(
        t("catalog.products.view.facet_meeting_point"),
        [
            createField(
                "meeting_point_name",
                t("catalog.products.fields.name"),
                constants.STRING_TYPE
            ),
            createField(
                "meeting_point_lat",
                t("catalog.products.fields.meeting_point_lat"),
                constants.STRING_TYPE
            ),
            createField(
                "meeting_point_lon",
                t("catalog.products.fields.meeting_point_lon"),
                constants.STRING_TYPE
            ),
            createCallbackField(
                "",
                "",
                constants.STRING_TYPE,
                (values, entity) => (
                    <Link Link
                        target="_blank"
                        href={`https://www.google.es/maps?z=10&q=loc:${entity.meeting_point_lat},${entity.meeting_point_lon}`}
                    >
                        {t("bookings.fields.pickup.map_link")}
                    </Link>
                )
            )
        ],
        (entity) => !_isEmpty(entity.meeting_point_lat) && !_isEmpty(entity.meeting_point_lon)
    ),
    createDataSetFacet(t("catalog.products.view.facet_billing"), [
        createField(
            "tax.name",
            t("catalog.products.fields.tax"),
            constants.STRING_TYPE,
            null,
            (entity) => entity.tax
        ),
        createCallbackField(
            "accountancy_config.name",
            t("catalog.products.fields.accountancy_config"),
            constants.STRING_TYPE,
            null,
            (entity) => entity?.accountancy_config
        ),
        createField(
            "enterprise.name",
            t("catalog.products.fields.enterprise_id"),
            constants.STRING_TYPE
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "state",
                    t("catalog.products.fields.state"),
                    constants.STRING_TYPE,
                    createProductStateMapping(t)
                ),
                null,
                (value) => value === "active" ? "positive" : "negative"
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "visibility",
                    t("common.visibility.title"),
                    constants.STRING_TYPE,
                    createCommonVisibilityMapping(t)
                ),
                null,
                (value) => value === "public" ? "positive" : "negative"
            ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "container.state",
                    t("catalog.product_containers.fields.state"),
                    constants.STRING_TYPE,
                    productContainerStateMapping(t)
                ),
                null,
                (value) => value === "published" ? "positive" : "negative"
            ),
        }),
    ]),
]