import constants from "../../../../components/entity/constants"
import { createAsyncSelectFormField, createCommonStateMapping, createCommonVisibilityMapping, createField, createFormField, createOptionsMapping, createProductStateMapping, createSelectFormField, productContainerStateMapping } from "../../../../components/helpers/fieldHelper"
import { getExperiencesFilter } from "../../../../lib/collection-filters"
import { buildOptions, sitesFetcher } from "../../../../lib/form-options-fetchers"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "product_id",
        "ID",
        constants.STRING_TYPE
    ),
    createField(
        "name",
        t("catalog.products.fields.name"),
        constants.TEXT_TYPE
    ),
    getExperiencesFilter(apiClient, t, true),
    createFormField(
        createSelectFormField(
            "state",
            t("catalog.products.fields.state"),
            createOptionsMapping(t, createProductStateMapping(t), true)
        ),
        false,
        "active"
    ),
    createFormField(
        createSelectFormField(
            "visibility",
            t("common.visibility.title"),
            createOptionsMapping(t, createCommonVisibilityMapping(t), true)
        ),
        false,
        "all"
    ),
    createFormField(
        createSelectFormField(
            "rates_state",
            t("catalog.product_containers.fields.state"),
            createOptionsMapping(t, productContainerStateMapping(t), true)
        ),
        false,
        "all"
    ),
    createAsyncSelectFormField(
        "site_id",
        t("sites.filter.title"),
        (params) => sitesFetcher(cache, apiClient, params).then((items) => {
            const sites = items.filter((site) => site.enterprise.id === user.corporate_account.enterprise.id)
            return buildOptions(sites, t)
        })
    ),
    createFormField(
        createAsyncSelectFormField(
            "tags",
            t("bookings.filter.tags"),
            () => {
                return cache.get("tags", []).then((items) =>
                    buildOptions(items.filter(x => !x.visible), t, "slug", "name", false)
                )
            },
            true
        ),
        false,
        []
    )
])
